
export function setAppState(monday, calendarInfo, step) {
    monday.get("context").then(res => {
      console.log(res);

      monday.storage.instance.setItem('user' + res.data.user.id + 'statStep', (calendarInfo.calendar_id + '') + ';' + (step + '')).then((res) => {
        console.log(res);
      });
    });
}


export function deleteAppState(monday) {
    monday.get("context").then(res => {
      console.log(res);
      monday.storage.instance.deleteItem('user' + res.data.user.id + 'statStep').then(item => {});

    });
}

