import React from 'react';
import { useEffect, useState } from 'react';
import './Connect.css';
import mainLogo from '../../main_logo.png';
import { BASE_URL } from '../../settings.js';
import { setAppState } from '../../tools.js';

const Calendar = ({ changeStep, monday, calendarInfo, step }) => {
  const [calendars, setCalendars] = useState([]);
  const [calendarsListIsOpen, setCalendarsListIsOpen] = useState(false);

  function removeCalendarHandler(calendar) {
     fetch(BASE_URL + "/remove-calendar/", {
        method: "post",
        headers: {
          'Accept': 'application/json',
          'Content-Type': 'application/json'
        },
        //make sure to serialize your JSON body
        body: JSON.stringify({
          calendarId: calendar.id,
        })
      })
      .then(res => res.json())
      .then(
        (result) => {
          console.log(result);
          if (result.removed_calendar_id) {
            setCalendars((prev) => {
              return [
                ...prev.filter((calendar) => {
                  return calendar.id !== result.removed_calendar_id; 
                }),
              ];
            }); 
          }
        },
        (error) => {
          console.log(error);
        }
    );
  }

 useEffect(() => {

    monday.get("context").then((res) =>{ 
      console.log(res);
      const accountId = res.data.account.id; 
      console.log(accountId);




      fetch(BASE_URL + "/get-account-calendars/", {
        method: "post",
        headers: {
          'Accept': 'application/json',
          'Content-Type': 'application/json'
        },
        //make sure to serialize your JSON body
        body: JSON.stringify({
          accountId: accountId,
        })
      })
      .then(res => res.json())
      .then(
        (result) => {
          console.log(result);
          if (result) {
            setCalendars((prev) => {
              return [
                ...prev,
                ...result.result.map((calendar) => {
                  return {
                    'id': calendar.id,        
                    'account_id': calendar.account_id, 
                    'title': calendar.title,
                    'color_name': calendar.color_name,
                    'color_code': calendar.color_code
                  }
                })
              ];
            }); 
          }
        },
        (error) => {
          console.log(error);
        }
    );
    });
 }, []);
  
  return (
    <div className="calendar-container">
      
      <h1 className="title">a.univers-transit</h1>
      
      <div className="connect-section">
        <div className="icon-container">
          <img 
            src={mainLogo} 
            style={{'width': '100px', 'height': '100px', 'marginLeft': 'auto', 'marginRight': 'auto'}}
          />
          { 
          /*
          <svg className="connect-icon" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2">
            <rect x="3" y="3" width="18" height="18" rx="2" ry="2" />
            <circle cx="8.5" cy="8.5" r="1.5" />
            <polyline points="21 15 16 10 5 21" />
          </svg>
           
          */ 
          }
        </div>
        
        <h2 className="connect-title">Create Calendar</h2>

        
        
        <button
          onClick={() => setCalendarsListIsOpen((prev) => !prev)}
          className="mb-4"  
        >
          Check calendar
        </button>
          { calendarsListIsOpen && <ul style={{'height': '184px', 'overflowY': 'scroll'}}>
            {
              calendars.map((calendar, index) => {
                return (

                      <li 
                        style={{'justifyContent': 'space-between'}}
                        className="flex mt-4 items-center"
                      >
                        <button className="flex items-center text-purple-600 hover:text-purple-800 checkCalendar"
                          onClick={() => {
                            calendarInfo.calendar_id = calendar.id;
                            changeStep(5);
                          }}
                          >
                            {calendar.title}
                        </button>
                        <button 
                            className="text-gray-500 removeCalendar"
                            onClick={() => { removeCalendarHandler(calendar) }}
                        >
                            🗑
                        </button>
                      </li>
                )
              })
            }
          </ul> }
        
        <div className="button-group">
          <button className="connect-button gmail" onClick={() => changeStep((prev) => prev+1)}>Add New</button>
        </div>
      </div>
    </div>
  );
};

export default Calendar;
