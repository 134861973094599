import React from 'react';
import { useState, useEffect } from 'react';
import { BASE_URL, ADDUSER_STEP, CONNECT_STEP } from '../../settings.js';

import { deleteAppState } from '../../tools.js';

const CalendarHeader = (
                    { 
                      calendar, 
                      headerType, 
                      setHeaderType, 
                      monthNames, 
                      monday, 
                      changeStep, 
                      calendarInfo, 
                      changeShowedCalendarId,
                      showedCalendarId,
                      viewGroups
                    } ) => {

  const [showedMonth, setShowedMonth] = useState(calendar.getDate().getMonth())
  const [calendars, setCalendars] = useState([]);

  useEffect(() => {
    monday.get("context").then((res) =>{ 
      console.log(res);
      const accountId = res.data.account.id; 
      console.log(accountId);

      fetch(BASE_URL + "/get-account-calendars/", {
        method: "post",
        headers: {
          'Accept': 'application/json',
          'Content-Type': 'application/json'
        },
        //make  esure to serialize your JSON body
        body: JSON.stringify({
          accountId: accountId,
        })
      })
      .then(res => res.json())
      .then(
        (result) => {
          console.log(result);
          if (result) {
            setCalendars((prev) => {
              return [
                ...result.result.map((calendar) => {
                  return {
                    'id': calendar.id,        
                    'account_id': calendar.account_id, 
                    'title': calendar.title,
                    'color_name': calendar.color_name,
                    'color_code': calendar.color_code
                  }
                })
              ];
            }); 
          }
        },
        (error) => {
          console.log(error);
        }
    );
    });
 }, [showedCalendarId]);

  console.log(calendar);
  return (
    <div className="flex items-center justify-between bg-white p-4 shadow-md" style={{'width': '100%'}}>
      <div className="flex items-center space-x-4 text-gray-800">
        <button 
          className="p-2 hover:bg-gray-100 rounded"
          onClick={() => {
            calendar.move(-1);
            setShowedMonth(calendar.getDate().getMonth());
          }}
        >
          &lt;
        </button>
        <button 
          className="p-2 hover:bg-gray-100 rounded"
          onClick={() => {
            calendar.move(1);
            setShowedMonth(calendar.getDate().getMonth());
          }}
        >
          &gt;
        </button>
        <span className="text-lg font-semibold">
          { 
            monthNames[showedMonth] + '-' + calendar.getDate().getFullYear()
          }
        </span>
        <button 
          className="text-blue-600 hover:underline"
          onClick={() => {
            calendar.today();
            setShowedMonth(calendar.getDate().getMonth());
          }}
        >
          Today
        </button>
        <select 
          className="flex items-center space-x-1" 
          onChange={(e) => {
            calendar.changeView(e.target.value);
            setHeaderType(e.target.value);
          }}

        >
          <option className="text-gray-600" value="month">Month</option>
          <option className="text-gray-600" value="week">Week</option>
          <option className="text-gray-600" value="day">Day</option>
        </select>

        <button
          className="flex items-center space-x-2 p-2 border border-gray-300 rounded-lg"
          onClick={
            () => {
              deleteAppState(monday);
              changeStep(CONNECT_STEP)
            }
          }
        >
          Add Calendar+
        </button>
      </div>
      <div className="flex items-center space-x-6">
        <div className="flex items-center space-x-2">
          <button
            className="flex items-center space-x-2 p-2 border border-gray-300 rounded-lg"
            onClick={
              () => {
                changeStep(ADDUSER_STEP);
              }
            }
          >
            Add Users+
          </button>
        </div>
        <div className="flex items-center space-x-2">
          <select 
            className="flex items-center space-x-1 text-gray-600" 
            multiple="true"
            onChange={(e) => {
              const options = [...e.target.selectedOptions];
              const optionsValues = options.map((option) => option.value);
              console.log(optionsValues)
              viewGroups.map((group) => {
                console.log(group.id.toString());
                console.log(optionsValues);
                console.log((optionsValues.indexOf(group.id.toString()) != -1) || (optionsValues.indexOf('0') != -1));
                if((optionsValues.indexOf(group.id.toString()) !== -1) || (optionsValues.indexOf('0') !== -1)) {
                  calendar.setCalendarVisibility(group.id.toString(), true);
                } else {
                  calendar.setCalendarVisibility(group.id.toString(), false);
                }
              })
            }}
          >
            <option className="text-sm" value="0">All groups</option>
            {viewGroups.map((group) => {
              return (
                <option className="text-sm" value={group.id}>{group.name}</option>
              )
            })}
          </select>
        </div>
        <div className="flex items-center space-x-2">
        {
          /*
          <div className="relative">
            <span className="absolute -top-2 -right-2 bg-red-600 text-white text-xs rounded-full px-1">2</span>
            <button className="p-2 bg-gray-100 rounded-full">
            </button>
          </div>
            */
        }
          <div 
            className="flex items-center space-x-2 p-2 border border-gray-300 rounded-lg"
          >
            <select
              onClick={(e) => {
                calendarInfo.calendar_id = e.target.value;
                changeShowedCalendarId(calendarInfo.calendar_id);
                console.log(calendarInfo.calendar_id);
              }}
            >
              { calendars.map((calendar) => {
                return (
                  <option
                    value={calendar.id}

                  >
                    <span className="h-4 w-4 bg-yellow-500 rounded-full inline-block" style={{'backgroundColor': calendar.color_code}}></span>
                    <span className="text-gray-800">{calendar.title}</span>
                    <button className="text-gray-600">
                    </button>
                  </option>
                  )
                })
              }
              <span className="h-4 w-4 bg-yellow-500 rounded-full inline-block"></span>
              <span className="text-gray-800"></span>
              <button className="text-gray-600">
              </button>

            </select>
            
          </div>
        </div>
      </div>
    </div>
  );
}

export default CalendarHeader;
