import React from 'react';
import { useState, useEffect } from 'react';
import './AddEvent.css';

import InputMoment from 'input-moment';
import moment from 'moment';


import "react-datetime/css/react-datetime.css";
import Datetime from "react-datetime";


import {default as SearchSelect} from './SelectBoards.js';
import { BASE_URL } from '../../settings.js';


let itemsCount;
let itemsPage = 0;

const AddEvent = ({ setCreatePopupOpen, calendarInfo, data, monday, boardEvents, calendar, userId, groups }) => {
  const [boards, setBoards] = useState([{name: 'Test Board', id: 0, value: 0}]);
  const [currentBoard, setCurrentBoard] = useState(0);

  const [boardItems, setBoardItems] = useState([]);
  const [boardItem, setBoardItem] = useState(0);

  const [title, setTitle] = useState('')
  const [start, setStart] = useState(data.start.toISOString().split('.')[0])
  console.log(start);
  const [end, setEnd] = useState(data.end.toISOString().split('.')[0])
  console.log(end);
  const [groupId, setGroupId] = useState(0)
  const [item, setItem] = useState({itemName: '', itemId: ''})


  //const [groups, setGroups] = useState([]);
  const [comment, setComment] = useState('');
  const [showAutomatizationSetting, setShowAutomatizationSetting] = useState(false);
  const [isGroupChecked, setIsGroupChecked] = useState(true);



  function isUniqueGroup(groupList, group) {
    console.log(group);
    return  (groupList.filter((item) => item.id === group.id)).length < 1;
  }


  function getBoardItems(value, cursor, tempBoardItems) {
    let query;
    if(cursor) {
      query = `query { boards (ids: ${value}){ items_page (limit: 500, cursor: "${cursor}"){ cursor items { id name creator_id state} }} }`;
    } else {
      query = `query { boards (ids: ${value}){ items_page (limit: 500){ cursor items { id name creator_id state} }} }`;
    }
    monday.api(query).then((res) => {
      console.log(res); 
      if(res) {
        if(res.data.boards[0].items_page.cursor === null){

          setBoardItems(() => {
            return [
              ...tempBoardItems,
              ...res.data.boards[0].items_page.items.map((item) => {
                    return {
                      name: item.name,
                      id: item.id,
                      value: item.id,
                      //groups: item.groups
                    }
              })
            ]
          })
          tempBoardItems.length = 0;
        } else {
          cursor = res.data.boards[0].items_page.cursor;
          tempBoardItems.push(...res.data.boards[0].items_page.items.map((item) => {
                    return {
                      name: item.name,
                      id: item.id,
                      value: item.id,
                      //groups: item.groups
                    }
              }))
          getBoardItems(value, cursor, tempBoardItems);
        }
        
      }
    })
  }


  function changeBoardHandler(value) {
    //setGroupId(e.target.value);
    const tempBoardItems = []
    getBoardItems(value, null, tempBoardItems);
  }

  function changeItemsHandler(value) {
    setItem(() => {
      return {
        itemId: value,
        itemName: boardItems.filter((item) => item.id == value)[0].name
      }
    })
  }


  function createEventHandler() {
    if (!groupId) {
      setIsGroupChecked(false);
      return ;
    } else {
      setIsGroupChecked(true);
    }
    console.log(
      {
          start: start,
          end: end,
          title: title,
          comment: comment,
          groupId: groupId, 
          boardId: currentBoard,
          itemId: item.itemId
        }
    )
    let body;
    if(currentBoard && item.itemId) {
      body = {
          start: start,
          end: end,
          title: title,
          comment: comment,
          groupId: groupId, 
          boardIds: [currentBoard],
          itemIds: [item.itemId]
        };
    } else {
      body = {
          start: start,
          end: end,
          title: title,
          comment: comment,
          groupId: groupId 
        };
    }
    console.log(body);

      fetch(BASE_URL + "/create-event/", {
        method: "post",
        headers: {
          'Accept': 'application/json',
          'Content-Type': 'application/json'
        },
        //make sure to serialize yojur JSON body
        body: JSON.stringify(body),
    
      })
      .then(res => res.json())
      .then(
        (results) => {
          console.log(results);
          console.log(boardEvents);

            boardEvents.push({
              id: results.result.id,
              calendarId: results.result.group_id + '',
              title: results.result.title,
              start: results.result.start,
              end: results.result.end,
              body: results.result.comment,
              row:{
                comment: results.result.comment
              }
            });
          
         calendar.clear(); 
          calendar.createEvents(boardEvents)
          console.log(boardEvents);


          calendar.clearGridSelections();
          setCreatePopupOpen(false);
           
        /*{
          id: 'event1',
          calendarId: 'cal2',
          title: 'Weekly meeting',
          start: '2024-08-07T09:00:00',
          end: '2024-08-07T10:00:00',
        }*/

        },
        // Примечание: важно обрабатывать ошибки именно здесь, а не в блоке catch(),
        // чтобы не перехватывать исключения из ошибок в самих компонентах.
        (error) => {
          console.log(error);
        }
      );

  }


  useEffect(() => {
    //Get boards 
    monday.api(`query { boards (state: active, limit: 100){ name id state type groups{title id} }}`).then((res) => {
      console.log(res); 
      if(res) {
        const newBoards = res.data.boards.filter((item) => item.type === 'board' && item.state === 'active');
        console.log('new boards:', newBoards);
        setBoards(() => {
          return [
            ...newBoards.map((item) => {
                  return {
                    name: item.name,
                    id: item.id,
                    value: item.id
                    //groups: item.groups
                }
              
            })
          ]
          
        })
      }

    //Get groups
      /* 
      fetch(BASE_URL + "/get-groups/", {
        method: "post",
        headers: {
          'Accept': 'application/json',
          'Content-Type': 'application/json'
        },
        //make sure to serialize your JSON body
        body: JSON.stringify({
          userId: userId,
          calendarId: calendarInfo.calendar_id, 
        })
      })
      .then(res => res.json())
      .then(
        (result) => {
          console.log(result);
          setGroupId(result.groups[0].id);
          setGroups((prev) => {
            return [
              ...prev,
                ...result.groups.map((item) => {
                  return {
                    id: item.id,
                    group: item.name,
                    color: item.colorCode
                  } 
                })
            ]
          });
          
        },
        // Примечание: важно обрабатывать ошибки именно здесь, а не в блоке catch(),
        // чтобы не перехватывать исключения из ошибок в самих компонентах.
        (error) => {
          console.log(error);
        }
      )
      */
      })


  }, []);
  
  return (
    <div 
      className="add-event-container" 
      style={{'position': 'absolute', 'left': '50%', 'transform': 'translateX(-50%)', 'zIndex': 1001}}
    >
      <h2>Add Event</h2>
      
      <input 
        type="text" 
        placeholder="Name" 
        className="input-field"
        value={title}
        onChange={(e) => setTitle(e.target.value)}
    />
      
      <div className="">
        { 
          isGroupChecked ||
            <span style={{'color': 'red'}}>Group is required!</span>
        }
        <select 
          className="input-field"
          onChange={(e) => { 
            console.log('groupId: ', e.target.value)
            return setGroupId(e.target.value)
          }}
          defaultValue={0}
        >
          <option value={0} disabled selected>Group</option>
           {
          groups.map((group) => {
              console.log(group);
              return (
                <option value={group.id}>{group.name}</option>
              )
            })
          }
        </select>
      </div>
      
      <div className="">
        <SearchSelect 
          options={boards.filter((item) => item.value == '1238995110')}
          placeholder={'Check board'}
          changeData={changeBoardHandler}
          setCurrentValue={setCurrentBoard}
          defaultValue={currentBoard}
        />
        { 
          /*<select 
          className="input-field"
          onChange={changeBoardHandler}
        >
          <option value="" disabled selected>Select board</option>
          {
            boards.map((board) => {
              return (
                <option value={board.id}>{board.name}</option>
              )
            })
          }
        </select>
        */ }
      </div>
      
      <div className="">
        <SearchSelect 
          options={boardItems}
          placeholder={'Check board item'}
          changeData={changeItemsHandler}
          setCurrentValue={setBoardItem}
          defaultValue={boardItem}
        />
        { /* 
        <select 
          className="input-field"
          onChange={(e) => {
            setItem(() => {
              return {
                itemId: e.target.value,
                itemName: boardItems.filter((item) => item.id == e.target.value)[0].name
              }
            })
          }}
        >
          <option value="" disabled selected>Select item</option>
            {
              boardItems.map((item) => {
                return (
                  <option value={item.id}>{item.name}</option>
                )
              })
            }
        </select>
       */}
      </div>
      
      <div 
        className="time-input"
        style={{'flex-direction': 'column'}}
      >
        
        <input type="datetime-local"
          className=""
          onChange={(e) => {
            setStart((prev) => {
              return e.target.value;
            });
          }}
          value={start}
        /> 
        
        
        <span>:</span>
        

        <input type="datetime-local"
          className=""
          onChange={(e) => {
            setEnd((prev) => {
              return e.target.value;
            });
          }}
          value={end}
        />
      </div>
      
      <textarea placeholder="Comment" className="input-field textarea" onChange={(e) => setComment(e.target.value)}>{comment}</textarea>
      
      <div className="toggle-container">
        <label className="switch">
          <input 
            type="checkbox" 
            onChange={() => setShowAutomatizationSetting((prev) => !prev )}
          />
          <span className="slider round"></span>
        </label>
        <span>Add one-time automation</span>
      </div>
      
      {
          showAutomatizationSetting &&
        <div className="automation-options">
          <div className="automation-row">
            <span>When</span>
            <span>Date</span>
          </div>
          <div className="automation-row">
            <select className="small-select">
              <option>Group entry</option>
            </select>
            <select className="small-select">
              <option>created</option>
            </select>
          </div>
          <div className="automation-row">
            <select className="small-select">
              <option>send</option>
            </select>
            <select className="small-select">
              <option>letter</option>
            </select>
          </div>
          <div className="automation-row">
            <select className="small-select">
              <option>to user</option>
            </select>
            <select className="user-select">
              <option>User</option>
            </select>
          </div>
        </div>
      }
      
      
      <button 
        className="create-button"
        onClick={createEventHandler}
      >
        Create Event
      </button>
        
    </div>
  );
}

export default AddEvent;
