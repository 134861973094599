import React from 'react';
import { useState, useEffect } from "react";
import {default as AddAutomatizationsItem} from './AutomatizationItem.js';
import {BASE_URL} from '../../settings.js';
import { setAppState } from '../../tools.js';

const AddCalendar = ({ monday, changeStep, calendarInfo, step }) => {

  function handleClickFinish() {
    //fetch("http://127.0.0.1:8000/test")
    fetch(BASE_URL + "/test")
      .then(res => res.json())
      .then(
        (result) => {
          console.log(result)
        },
        // Примечание: важно обрабатывать ошибки именно здесь, а не в блоке catch(),
        // чтобы не перехватывать исключения из ошибок в самих компонентах.
        (error) => {
          console.log(error);
        }
      )
  }

  const [automatizationsList, setAutomatizationsList] = useState([])

  useEffect(() => {
    setAppState(monday, calendarInfo, step);
  }, [monday])
  return (
    <div className="max-w-4xl mx-auto p-6">
      <div className="text-gray-500 mb-4">
        <span>Calendar</span> / <span>Add Calendar</span>
      </div>
      
      <h1 className="text-3xl font-bold text-gray-800 mb-8">Add Calendar</h1>
      
      <div className="flex justify-between mb-12">
        {['General Info', 'Groups', 'Users', 'Automation'].map((step, index) => (
          <div key={step} className={`flex items-center ${index < 4 ? 'text-purple-600' : 'text-gray-400'}`}>
            <span className={`w-8 h-8 rounded-full flex items-center justify-center mr-2 ${index < 4 ? 'bg-purple-600 text-white' : 'bg-gray-200'}`}>
              {index + 1}
            </span>
            <span>{step}</span>
          </div>
        ))}
      </div>

      <h2 className="text-2xl font-semibold mb-6">Automatization</h2>

      <div className="border border-dashed border-gray-300 rounded-lg p-4 mb-6">
        <button className="flex items-center text-purple-600" onClick={() => setAutomatizationsList((prevState) => [...prevState, 1])}>
          <span className="mr-2">+</span>
          Add Automation
        </button>
      </div>

      {automatizationsList.map((_, index,) => (
        <AddAutomatizationsItem 
          key={index} 
          auotomatizationsList={automatizationsList}
          setAutomatizationsList={setAutomatizationsList}
          index={index}/>))}


      <div className="flex justify-between items-center mt-8">
        <button className="px-6 py-2 border rounded">Back</button>
        <span className="text-gray-500">Skip this step and finish</span>
        <button className="px-6 py-2 bg-purple-600 text-white rounded" onClick={() => handleClickFinish()}>Finish</button>
      </div>
    </div>
  );
};

export default AddCalendar;
