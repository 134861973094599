import React from 'react';
import { useState, useEffect, useRef } from "react";

import { BASE_URL } from '../settings.js';
import { setAppState } from '../tools.js';

const AddCalendar = ({ calendarInfo, monday, changeStep, step }) => { 
  let workSpaceUsers = '';
  const [users, setUsers] = useState([]);
  const [groups, setGroups] = useState([]);
  const [participants, setParticipants] = useState([]);
  const [addUser, setAddUser] = useState({});
  const [userInfo, setUserInfo] = useState({data: {users: []}});
  const [constrains, setConstrains] = useState([]);

  const defaultConstrain = useRef(1);


  function nextButtonHandler() {
    console.log(users);
    fetch(BASE_URL + "/create-users/", {
        method: "post",
        headers: {
          'Accept': 'application/json',
          'Content-Type': 'application/json'
        },
        //make sure to serialize your JSON body
        body: JSON.stringify({
          users: users, 
        })
      })
      .then(res => res.json())
      .then(
        (result) => {
          console.log(result);
          changeStep((prev) => prev + 2);
        },
        (error) => {
          console.log(error);
        }
      )
  }

  function createParticipant(userId, groupId, constrainsId) {
    console.log(constrainsId);
    fetch(BASE_URL + "/create-participant/", {
        method: "post",
        headers: {
          'Accept': 'application/json',
          'Content-Type': 'application/json'
        },
        //make sure to serialize your JSON body
        body: JSON.stringify({
          calendarId: calendarInfo.calendar_id,
          userId: userId, 
          groupId: groupId,
          constrainsId: constrainsId.current
        })
      })
      .then(res => res.json())
      .then(
        (result) => {
          console.log(result);
          setGroups((prev) => {
            return [ 
                ...prev.map((group) => {
                  if(group.id == result.group.id){
                    return result.group;
                  } else {
                    return group;
                  }
                })
              ]
          })

        },
        (error) => {
          console.log(error);
        }
      )
  }


  function changeParticipiantConstrain(participantId, constrainsId, groupId) {
    console.log(constrainsId);
    fetch(BASE_URL + "/change-participant-constrain/", {
        method: "post",
        headers: {
          'Accept': 'application/json',
          'Content-Type': 'application/json'
        },
        //make sure to serialize your JSON body
        body: JSON.stringify({
          participantId: participantId, 
          constrainsId: constrainsId,
          groupId: groupId,
        })
      })
      .then(res => res.json())
      .then(
        (result) => {
          console.log(result);
          setGroups((prev) => {
            return [ 
                ...prev.map((group) => {
                  if(group.id == result.group.id){
                    return result.group;
                  } else {
                    return group;
                  }
                })
              ]
          })

        },
        (error) => {
          console.log(error);
        }
      )
  }


  function deleteParticipant(participantId, groupId) {
    fetch(BASE_URL + "/delete-participant/", {
        method: "post",
        headers: {
          'Accept': 'application/json',
          'Content-Type': 'application/json'
        },
        //make sure to serialize your JSON body
        body: JSON.stringify({
          groupId: groupId,
          participantId: participantId
        })
      })
      .then(res => res.json())
      .then(
        (result) => {
          console.log(result);
          setGroups((prev) => {
            return [ 
                ...prev.map((group) => {
                  if(group.id == result.group.id){
                    return result.group;
                  } else {
                    return group;
                  }
                })
              ]
          })

        },
        (error) => {
          console.log(error);
        }
      )
  }


  useEffect(() => {
    setAppState(monday, calendarInfo, step);
    monday.api(`query { users { id, name, email } }`).then(res => {
      console.log(res);
      if (!res) {
        //Test START
        setUserInfo({
          data: {
            users: [
              {id: '46026680', email: 'Ilan REBIBO'},
              {id: '46026710', email: 'Sergei Gofman'},
              {id: '49808684', email: 'Shuki MITERAN'},
              {id: '50989500', email: 'Avi AYASH'},
              {id: '50989525', email: 'Hagai SHAHAR'},
              {id: '53558062', email: 'Ed SINGER'},
              {id: '58042928', email: 'Gil Wanderman'},
              {id: '58965102', email: 'Mendy Grauman'},
              {id: '62243167', email: 'RUTH LAREDO'},
            ]
          }
          //Test END
        });
      } else {
        setUserInfo(res);
        setAddUser((prev) => {
          return {
            userId: res.data.users[0].id,
            email: res.data.users[0].email,
            groupId: prev.groupId
          }
        });
        console.log(res);
      }
      /* { data: { users: [{id: 12312, name: "Bart Simpson"}, {id: 423423, name: "Homer Simpson"}] } } */


      console.log(calendarInfo);
      fetch(BASE_URL + "/get-groups/", {
        method: "post",
        headers: {
          'Accept': 'application/json',
          'Content-Type': 'application/json'
        },
        //make sure to serialize your JSON body
        body: JSON.stringify({
          calendarId: calendarInfo.calendar_id, 
        })
      })
      .then(res => res.json())
      .then(
        (result) => {
          console.log(result);

          //changeStep((prev) => prev + 1)
          setUsers((prev) => {
              console.log(res);
              return [
                ...prev,
                ...res.data.users.map((item) => {
                  return { 
                    email: item.email,
                    name: item.name,
                    userId: item.id,
                  }  
                })
              ];
          }); 

          setGroups((prev) => {
            return [
                ...result.groups.map((item) => {
                  return {
                    id: item.id,
                    name: item.name,
                    color: item.colorCode,
                    participants: item.participants
                  } 
                })
            ]
          });


          setAddUser((prev) => {
              console.log(prev)
            return {
              id: prev.id,
              userId: prev.userId,
              email: prev.email,
              group: result.groups[0].name,
              groupId: result.groups[0].id
            }
          });




          fetch(BASE_URL + "/get-constrains/", {
            method: "post",
            headers: {
              'Accept': 'application/json',
              'Content-Type': 'application/json'
            },
            //make sure to serialize your JSON body
          })
          .then(res => res.json())
          .then(
            (result) => {
              console.log(result);
              setConstrains(result.constrains);
            },
            (error) => {
              console.log(error);
            }
          )

          
        },
        // Примечание: важно обрабатывать ошибки именно здесь, а не в блоке catch(),
        // чтобы не перехватывать исключения из ошибок в самих компонентах.
        (error) => {
          console.log(error);
        }
      )

    });

  }, []);


  return (
    <div 
      className="max-w-4xl mx-auto p-6"
      style={
        {'minWidth': '27rem',
          'overflow': 'scroll'
        }}
    >
      {
        /*
          <div className="text-gray-500 mb-4">
            <span>Calendar</span> / <span>Add Calendar</span>
          </div>

        <h1 className="text-3xl font-bold text-gray-800 mb-8">Add Calendar</h1>
         */
      }
      
      
      <div className="flex justify-between mb-12">
        {['General Info', 'Groups', 'Users', 'Automation'].map((step, index) => (
          <div 
            key={step} 
            className={`flex items-center ${index < 3 ? 'text-purple-600' : 'text-gray-400'}`}
            style={{'flexDirection': 'column'}}
          >
            <span className={`w-8 h-8 rounded-full flex items-center justify-center mr-2 ${index < 3 ? 'bg-purple-600 text-white' : 'bg-gray-200'}`}>
              {index + 1}
            </span>
            <span>{step}</span>
          </div>
        ))}
      </div>
      

      { groups.map((group) => {
        console.log(groups)
        return (
          <>
            <h2 className="text-2xl font-semibold mb-6">Group: {group.name}</h2>

            <h3 className="font-semibold">Users</h3>

            <table className="w-full mb-6">
              <thead>
                <tr className="text-left border-b">
                  {
                    <th className="pb-2"></th>
                  }
                  <th className="pb-2">constrains</th>
                  <th className="pb-2">delete</th>
                  <th className="pb-2"></th>
                </tr>
              </thead>
              <tbody>
                {
                  group.participants.map((participant, index) => ( 
                  <tr key={index} className="border-b">
                    <td className="py-3 flex items-center">
                      {
                        users.filter((user) => user.userId == (participant.user+'')).map((user) => user.name)
                      }
                    </td>
                    <td className="py-3">
                      <div className="flex items-center">
                        <span className={`w-3 h-3 rounded-full ${participant.color} mr-2`}></span>
                        <select 
                           className="border rounded p-1"
                           onChange={(e) => {
                              changeParticipiantConstrain(participant.id, e.target.value, group.id);
                            
                            }
                          } 
                        >
                          {
                            constrains.map((constrain) => {
                                console.log(constrains)
                              return (
                                <option selected={constrain.id == participant.constrain.id ? true : false} key={constrain.id} value={constrain.id}>{constrain.name}</option>
                              )
                            })
                          }

                        </select>
                      </div>
                    </td>
                    <td className="py-3">
                      <button 
                          className="text-gray-500"
                          onClick={() => {
                            deleteParticipant(participant.id, group.id);
                          /* setUsers((prev) => {
                            console.log(users);
                            return [
                              ...prev.filter((user) => {
                                return user.userId != participant.user+'';
                              })
                            ]
                          }) */
                            } 
                          } 
                      >
                          🗑
                      </button>
                    </td>
                  </tr>
                ))}
                <tr>
                  <td>
                    <button className="mt-4 flex items-center text-purple-600 hover:text-purple-800"
                      onClick={() => {
                        if(addUser.userId == 0) return;

                        console.log(addUser);
                        console.log(users);
                        createParticipant(addUser.userId, group.id, defaultConstrain);
                      }}            
                    >
                      <svg className="w-5 h-5 mr-2" fill="none" stroke="currentcolor" viewbox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                        <path strokelinecap="round" strokelinejoin="round" strokewidth="2" d="m12 4v16m8-8h4"></path>
                      </svg>
                      add user
                    </button>
                  </td>
                </tr>
                <tr>
                  
                  <td className="py-3" colspan="3">
                    <div className="flex items-center">
                        <span className={`w-3 h-3 rounded-full mr-2`}></span>
                        <select 
                          className="border rounded p-1"
                          value={addUser.userId}
                          onChange={(e) => {
                              if (e.target.value == 0) {
                                return;
                              }
                              
                              console.log(e.target.value)
                              setAddUser((prev) => {
                                return {
                                  userId: e.target.value,
                                }
                              })
                            }}
                        >
                          {
                            userInfo && userInfo.data && userInfo.data.users.map((user, index) => {
                              return (
                                <option  value={user.id} key={user.id}>{user.name}</option>
                              )
                            })
                          }

                        </select>
                      </div>
                  </td>
                  <td classname="py-3">
                      <div classname="flex items-center">
                        <span classname={`w-3 h-3 rounded-full mr-2`}></span>
                        
                      </div>
                    </td>
                  <td classname="py-3">
                    
                  </td>
                </tr>
              </tbody>
            </table>
          </>
        )
      })}
      


      <div className="flex justify-between">
        <button 
          className="px-6 py-2 bg-purple-600 text-white rounded"
          onClick={nextButtonHandler}
        >
          Continue
      </button>
      </div>
    </div>
  );
};

export default AddCalendar;
